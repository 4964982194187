import React from 'react';

export function TMButton({ imgSrc, label, onClick, selected, disabled }) {
    return (
        <button onClick={onClick} disabled={selected || disabled} className="d-flex p-1 mb-1 align-items-center justify-content-center">
            <div className="position-relative d-flex align-items-center justify-content-center rounded-circle me-2 bg-white" style={{ width:'30px', height:'30px'}}>
                {
                    imgSrc 
                        ?
                            <img src={imgSrc} className="bg-transparent" style={{width:'20px', height:'20px'}}/>
                        :
                            <span className="bg-transparent" style={{width:'20px', height:'20px'}}>
                                {label[0]}
                            </span>
                }
                {
                    selected && (
                        <div className="bg-success rounded-circle position-absolute" style={{width:'12px',height:'12px',bottom:'-2px',right:'-2px'}}/>
                    )
                }
            </div>
            <span class="text-white">{label}</span>
        </button>
    );
}
