import React, { useEffect, useState } from 'react';
import { digitFormat, moneyFormat } from "../utils";
import { io } from 'socket.io-client'; // Import Socket.IO client

const OverallUSDIPayout = ({onStatusChange}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [updating, setUpdating] = useState(false);
    const [status, setStatus] = useState("calculated");
    const [distributedData, setDistributedData] = useState(null);
    const [distributedError, setDistributedError] = useState(null);
    const [distributedLoading, setDistributedLoading] = useState(true);
    const [isDistributed, setIsDistributed] = useState(false); // New state to track if distribution is complete

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://payout-calculator-256611876551.europe-west1.run.app/trigger-payout-calculator', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        update: false
                    }),
                });

                if (!response.ok) throw new Error('Network response was not ok');

                const result = await response.json();
                setData(result.calculated_data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchDistributedData = async () => {
            try {
                setDistributedLoading(true);
                const response = await fetch('https://usdi-api-256611876551.europe-west1.run.app/overall_usdi_payout');

                if (!response.ok) throw new Error('Failed to fetch distributed data');

                const result = await response.json();
                setDistributedData(result);
            } catch (error) {
                setDistributedError(error);
            } finally {
                setDistributedLoading(false);
            }
        };
        fetchDistributedData();
    }, []);

    const handleUpdate = async () => {
        try {
            setUpdating(true);
            const response = await fetch('https://payout-calculator-256611876551.europe-west1.run.app/trigger-payout-calculator', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ update: false }),
            });

            if (!response.ok) throw new Error('Failed to trigger distribution calculation');

            const result = await response.json();
            setData(result.calculated_data);
            const distResponse = await fetch('https://usdi-api-256611876551.europe-west1.run.app/overall_usdi_payout');
            if (!distResponse.ok) throw new Error('Failed to fetch updated distributed data');

            const distResult = await distResponse.json();
            setDistributedData(distResult);
        } catch (error) {
            setError(error);
        } finally {
            setUpdating(false);
        }
    };

    const handleStatusChange = async (newStatus) => {
        const isConfirmed = window.confirm(`Are you sure you want to change the status to ${newStatus}?`);
        if (isConfirmed) {
            setStatus(newStatus);
            onStatusChange(newStatus);

            const response = await fetch('https://payout-calculator-256611876551.europe-west1.run.app/trigger-payout-calculator', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ update: true }),
            });

            if (!response.ok) throw new Error('Failed to trigger distribution calculation');

            setData(await response.json());
            await handleUpdate();

            // Set up the socket connection to listen for the completion message
            // const socket = io.connect('https://distribution-calculator-256611876551.europe-west1.run.app');
            // // const socket = io('http://localhost:8080');
            //
            // console.log("trying to connect to socket")
            // socket.on('connect', () => {
            //     console.log('Connected to socket');
            // });
            //
            // socket.on('payout_complete', () => {
            //     setIsDistributed(true); // Mark as distributed
            //     socket.disconnect(); // Close the socket after receiving the message
            //     console.log("success terminated for socket")
            // });
            //
            setStatus("calculated");
        }
    };

    if (loading || distributedLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;
    if (distributedError) return <div>Error: {distributedError.message}</div>;

    return (
        <>
            <div className='button_wrapper'>
                <h3>Overall USDI Payout</h3>
                <button className={`button ${updating ? 'updating' : ''}`} onClick={handleUpdate} disabled={updating}>
                    {updating ? 'Updating' : 'Update'}
                </button>
                {isDistributed && <span>Distributed</span>} {/* Display "Distributed" message */}
            </div>

            {data && distributedData && (
                <div className="table-responsive noto">
                    <table className="table table-striped table-bordered mt-3">
                        <thead className="thead-dark">
                        <tr>
                            <th>Status</th>
                            <th>Block No</th>
                            <th>Total Assets Value</th>
                            <th>USDI Locked</th>
                            <th>USDI In Circulation</th>
                            <th>Distribution ID</th>
                            <th>Available Distribution Value</th>
                            <th>Distribution Value</th>
                            <th>Distribution Amount Fees</th>
                            <th>Final Distribution Amount After Management Fees</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <select
                                        value={status}
                                        onChange={(e) => handleStatusChange(e.target.value)}
                                        className="status_dropdown"
                                    >
                                        <option value="calculated">Calculated</option>
                                        <option value="distributed">Distributed</option>
                                    </select>
                                </td>
                                <td>{digitFormat(data.current_block_number)}</td>
                                <td>{moneyFormat(data.total_assets_value)}</td>
                                <td>{digitFormat(data.usdi_locked)}</td>
                                <td>{digitFormat(data.usdi_in_circulation)}</td>
                                <td>NA</td>
                                <td>{moneyFormat(data.available_distribution_value)}</td>
                                <td>{moneyFormat(data.distribution_value)}</td>
                                <td>{moneyFormat(data.distribution_amount_of_fees)}</td>
                                <td>{moneyFormat(data.final_distribution_amount)}</td>
                            </tr>
                            {distributedData.slice().sort((a, b) => b.block_no - a.block_no).map((row, index) => (
                                <tr key={index}>
                                    <td>Distributed</td>
                                    <td>{digitFormat(row.block_no)}</td>
                                    <td>{moneyFormat(row.total_assets_value)}</td>
                                    <td>{digitFormat(row.usdi_locked)}</td>
                                    <td>{digitFormat(row.usdi_in_circulation)}</td>
                                    <td>{distributedData.length - index}</td>
                                    <td>{moneyFormat(row.available_distribution_value)}</td>
                                    <td>{moneyFormat(row.distribution_value)}</td>
                                    <td>{moneyFormat(row.distribution_amount_fees)}</td>
                                    <td>{moneyFormat(row.final_distribution_amount_after_management_fees)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
};

export default OverallUSDIPayout;
