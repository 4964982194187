import React, { useState } from 'react';

export const TokenCreator = ({web3, account}) => {
    const [isCompiling, setIsCompiling] = useState(false);
    const [isDeploying, setIsDeploying] = useState(false);
    const [instructionInfo, setInstructionInfo] = useState(null);

    async function handleDeployFormSubmit(e) {
        e.preventDefault();
        setIsCompiling(true);
        setInstructionInfo(null);
        const form = e.target;
        const formData = {
            name: form.name.value,
            symbol: form.symbol.value,
            decimals: form.decimals.value || '18',
            mint: form.mint.value || '0',
            license: form.license.value || 'MIT'
        };

        const addr = 'https://token-creation-distribution-be-256611876551.us-central1.run.app';
        const endpoint = '/ERC20-gen';

        try {
            const response = await fetch(addr + endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData), // Send form data as JSON
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const responseData = await response.json();

            const instructionInfo = {
                stdJsonInput: responseData.stdJsonInput,
                token: responseData.token,
                solcVerison: responseData.solcVerison
            };
            setInstructionInfo(instructionInfo);
            deployContract(responseData);
        } catch (error) {
            console.error("Submission error:", error);
            console.log(`Error: ${error.message}`);
        }
        setIsCompiling(false);
    }

    async function deployContract(compilationResponse) {
        setIsDeploying(true);
        const contract = new web3.eth.Contract(compilationResponse.abi);
        contract.handleRevert = true;

        const contractDeployer = contract.deploy({
            data: compilationResponse.bin,
            arguments: [ ]     // arguments for the contract constructor
        });
        const gas = await contractDeployer.estimateGas({
            from: account
        });

        try {
            const tx = await contractDeployer.send({
                from: account,
                gas,
                gasPrice: await web3.eth.getGasPrice()
            });
            console.log('Contract deployed at address: ' + tx.options.address);
        } catch(error) {
            console.error("Failed to deploy contract:", error);
        }
        setIsDeploying(false);
    }

    const StdJsonInstruction = ({ instructionInfo }) => {
        const handleDownload = () => {
            const blob = new Blob(
                [JSON.stringify(instructionInfo.stdJsonInput)], 
                { type: "application/json" }
            );
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = `${instructionInfo.token.symbol} ${instructionInfo.solcVerison} StdJsonInput.json`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        };

        return (
            <>
                <h4>Verify & Publish on<br/>Block Explorer</h4>
                <br/>
                <span>
                    Compiler Type:<br/> Solidity (Standard-Json-Input)<br/><br/>
                    Compiler Version: {instructionInfo.solcVerison}<br/><br/>
                    License Type: {instructionInfo.token.license}<br/>
                </span>
                <br/>
                <button onClick={handleDownload} className="text-white p-2">Download Standard-Input-Json file</button>
            </>
        );
    }

    const DeployButtonText = ({ account, isCompiling, isDeploying }) => {
        if (!account) {
            return 'Please select a wallet account';
        }
        if (isCompiling) {
            return 'Compiling';
        }
        if (isDeploying) {
            return 'Deploying';
        }
        return 'Deploy';
    }

    const formStyles = {
        form: {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            maxWidth: '400px'
        },
        row: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px'
        },
        label: {
            width: '100px',
            textAlign: 'right',
            color: 'white'
        },
        input: {
            flex: 1,
            color: 'white',
            padding: '5px'
        },
        button: {
            color: 'white',
            padding: '10px',
            width: '100%'
        },
    };

    const handleTextInput = (e) => {
        let value = e.target.value;
        value = value.replace(/\s/g, '');
        e.target.value = value;
    }

    const handleNameInput = (e) => {
        let value = e.target.value;
        value = value.replace(/^\s+/, '').replace(/\s{2,}/g, ' ');
        e.target.value = value;
    }

    const handleDecimalsInput = (e) => {
        let value = e.target.value;
        value = value.replace(/^0+/, '').replace(/[^\d]/g, '');
        e.target.value = value;
    }

    const handleMintInput = (e) => {
        let value = e.target.value;
        value = value.replace(/[^\d]/g, '');
        if (value === '00') {
            value = '0';
        } else if (value.startsWith('0') && value.length > 1) {
            value = value.replace(/^0+/, '');
        }
        e.target.value = value;
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-7 col-12">
                    <h4>Create an ERC-20 Token</h4>
                    <br/>
                    <form onSubmit={handleDeployFormSubmit} style={formStyles.form}>
                        <div style={formStyles.row}>
                            <span style={formStyles.label}>Name:</span>
                            <input required name="name" placeholder="name" style={formStyles.input} onInput={handleNameInput}/>
                        </div>
                        <div style={formStyles.row}>
                            <span style={formStyles.label}>Symbol:</span>
                            <input required name="symbol" placeholder="symbol" style={formStyles.input} onInput={handleTextInput}/>
                        </div>
                        <div style={formStyles.row}>
                            <span style={formStyles.label}>Decimals:</span>
                            <input name="decimals" placeholder="18" style={formStyles.input} onInput={handleDecimalsInput}/>
                        </div>
                        <div style={formStyles.row}>
                            <span style={formStyles.label}>Mint:</span>
                            <input name="mint" placeholder="0" style={formStyles.input} onInput={handleMintInput}/>
                        </div>
                        <div style={formStyles.row}>
                            <span style={formStyles.label}>License:</span>
                            <input name="license" placeholder="MIT" style={formStyles.input} onInput={handleTextInput}/>
                        </div>
                        <button type="submit" disabled={!account||isCompiling||isDeploying} style={formStyles.button}>
                            <DeployButtonText account={account} isCompiling={isCompiling} isDeploying={isDeploying}/>
                        </button>
                    </form>
                </div>
                <div className="col-md-5 col-12">
                    { instructionInfo && <StdJsonInstruction instructionInfo={instructionInfo}/> }
                </div>
            </div>
        </div>
    )
}
