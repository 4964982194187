import {useEffect, useState} from "react";
import {fetchadjustDailyPortfolioData} from "./fetchDailyPortfolioData";
import {getLineGradient, getLineBorderGradient} from "./GradientUtils";

const isBrowser = typeof window !== "undefined";
const isMobile = isBrowser && window.innerWidth < 768;

const sizeResponsive = {
    padding: {
        top: isMobile ? 12 : 21,
        right: isMobile ? 12 : 41,
        bottom: isMobile ? 14 : 19,
        left: isMobile ? 15 : 21,
    },
    titleFont: {
        size: isMobile ? 8 : 12,
    },
    bodyFont: {
        size: isMobile ? 12 : 16,
        lineHeight: isMobile ? 1 : 1.5,
    },
    caretSize: isMobile ? 8 : 10,
};

export const verticalLinePlugin = {
    id: "verticalLinePlugin",
    afterDatasetsDraw: function (chart) {
        const {
            ctx,
            chartArea: {top, bottom},
            scales: {x},
        } = chart;
        if (chart.tooltip._active && chart.tooltip._active.length) {
            const activePoint = chart.tooltip._active[0];
            const xCoord = activePoint.element.x;

            ctx.save();
            ctx.beginPath();
            ctx.setLineDash([]);
            ctx.moveTo(xCoord, top);
            ctx.lineTo(xCoord, bottom);
            ctx.lineWidth = 0.89;
            ctx.strokeStyle = "rgba(255, 255, 255, 0.50)";
            ctx.stroke();
            ctx.restore();
        }
    },
};

export const useLineData = () => {
    const [lineData, setLineData] = useState({
        labels: [],
        datasets: [
            {
                label: "Total MEV Distributed through OMAX1 (in ETH)",
                data: [],
                fill: true,
                backgroundColor: (context) => {
                    const chart = context.chart;
                    const {chartArea} = chart;
                    if (!chartArea) {
                        return "null";
                    }
                    return getLineGradient(chart);
                },
                borderColor: (context) => {
                    const chart = context.chart;
                    const {chartArea} = chart;
                    if (!chartArea) {
                        return null;
                    }
                    return getLineBorderGradient(chart);
                },
                borderWidth: 1.5,
                tension: 0.5,
            },
        ],
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchadjustDailyPortfolioData();
                const formattedLabels = data.labels.map((label) => {
                    const date = new Date(label);
                    return date.toISOString().split("T")[0];
                });

                setLineData({
                    labels: formattedLabels,
                    datasets: [
                        {
                            label: "Total MEV Distributed through OMAX1 (in ETH)",
                            data: data.datasets4,
                            fill: true,
                            backgroundColor: (context) => {
                                const chart = context.chart;
                                const {chartArea} = chart;
                                if (!chartArea) {
                                    return "null";
                                }
                                return getLineGradient(chart);
                            },
                            borderColor: (context) => {
                                const chart = context.chart;
                                const {chartArea} = chart;
                                if (!chartArea) {
                                    return null;
                                }
                                return getLineBorderGradient(chart);
                            },
                            borderWidth: 1.5,
                            tension: 0.5,
                        },
                    ],
                });
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    return lineData;
};


export const getLineOptions = (width) => {
    const isMobile = width < 1150;

    const options = {
        animation: {
            duration: 1100,
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
                caretSize: sizeResponsive.caretSize,
                titleAlign: "left",
                bodyAlign: "left",
                padding: {
                    top: sizeResponsive.padding.top,
                    right: sizeResponsive.padding.right,
                    bottom: sizeResponsive.padding.bottom,
                    left: sizeResponsive.padding.left,
                },
                titleFont: {
                    style: "14px",
                    weight: "normal",
                    size: sizeResponsive.titleFont.size,
                },
                bodyFont: {
                    style: "18px",
                    weight: "normal",
                    size: sizeResponsive.bodyFont.size,
                },
                intersect: false,
                mode: "index",
                position: "nearest",
                titleColor: "#CFD3D2",
                bodyColor: "#ffffff",
                backgroundColor: "rgba(16, 20, 20, 0.85)",
                borderColor: "rgba(69, 79, 102, 1)",
                borderWidth: 1,
                displayColors: false,
                callbacks: {
                    title: function (tooltipItems) {
                        return tooltipItems[0].label;
                    },
                    label: function (tooltipItem) {
                        const dataset =
                            tooltipItem.chart.data.datasets[tooltipItem.datasetIndex];
                        const value = dataset.data[tooltipItem.dataIndex];
                        if (value !== undefined) {
                            // First line: Original value formatted
const roundedValue = Number(value).toFixed(4); // 先对数值四舍五入到4位小数（返回字符串）
const originalValueFormatted = `$${Number(roundedValue).toLocaleString()}`;
                            // Second line: Percentage calculation
                            const percentageValue = ((value - 1) / 1) * 100;
                            const sign = percentageValue >= 0 ? '+' : '';

                            const percentageFormatted = `${sign}${percentageValue.toFixed(2)}%`;

                            // Return an array with two lines
                            return [originalValueFormatted, percentageFormatted];
                        }
                        return "";
                    },
                },
            },
        },
        elements: {
            point: {
                radius: 0,
            },
            line: {
                tension: 0.5, // 增加 tension 值
            },
        },
    //
        scales: {
            x: {
                type: "time",
                time: {
                    unit: "month",
                    tooltipFormat: "MMMM dd, yyyy",
                },
                border: {
                    display: false,
                },
                grid: {
                    display: false,
                },
                ticks: {
                    maxRotation: 0,
                    minRotation: 0,
                    autoSkip: true,
                    minTicksLimit: 4,
                    maxTicksLimit: 4,
                    color: "rgba(255, 255, 255, 0.70)",
                    font: {
                        style: "normal",
                        size: 14,
                    },
                    callback: function (value) {
                        const date = new Date(value);
                        const formattedValue = date.toLocaleDateString("en-US", {
                            month: "short",
                            year: "numeric",
                        });
                        return isMobile ? formattedValue.split(" ") : formattedValue;
                    },
                    padding: 10,
                },
            },
            y: {
                beginAtZero: true,
                min: 0.9,
                border: {
                    display: false,
                },
                grid: {
                    color: "rgba(255, 255, 255, 0.2)",
                    lineWidth: 0.5,
                },
                ticks: {
                    color: "rgba(255, 255, 255, 0.70)",
                    font: {
                        size: 14,
                    },
                    callback: function (value) {
                        if (value >= 1e6) {
                            return value / 1e6 + "M";
                        } else if (value >= 1e3) {
                            return value / 1e3 + "K";
                        } else {
                            return value;
                        }
                    },
                     padding: 10,
                },
            },
        },
    };

    return options;
};
