import {fetchJWT} from "./login";
import axios from "axios";
import { ENDPOINT_BASE_URL } from "../../configs/config";

export const fetchDailyPortfolioData = async (startDate, endDate) => {
    try {
        const TEST_JWT = await fetchJWT();
        const response = await axios.get(`${ENDPOINT_BASE_URL}/track_records_daily_latest`, {
            headers: {
                'Authorization': `Bearer ${TEST_JWT}`
            },
            params: {
                start_date: startDate, // Pass start date in 'YYYY-MM-DD' format
                end_date: endDate // Optional: pass end date in 'YYYY-MM-DD' format
            }
        });
        // Log and process the response data

        const dailyDataArray = response.data.data.map(record => record.portfolio_implied_apr);
        const thirty_day_apy = response.data.data.map(record => record.thirty_day_apy);
        const ninety_day_apy = response.data.data.map(record => record.ninety_day_apy);
        const correspondingDay = response.data.data.map(record => record.timestamp);
        const dollar_value_growth = response.data.data.map(record => record.dollar_value_growth);

        return {
            datasets1: dailyDataArray,
            datasets2: thirty_day_apy,
            datasets3: ninety_day_apy,
            datasets4: dollar_value_growth,
            labels: correspondingDay
        };
    } catch (error) {
        console.error('Error fetching portfolio track records:', error);
        throw error;
    }
};


// speciallized fetch from july 23nd.
export const fetchadjustDailyPortfolioData = async (startDate, endDate) => {
    try {
        const TEST_JWT = await fetchJWT();
        const response = await axios.get(`${ENDPOINT_BASE_URL}/track_records_daily_latest`, {
            headers: {
                'Authorization': `Bearer ${TEST_JWT}`
            },
            params: {
                start_date: startDate, // Pass start date in 'YYYY-MM-DD' format
                end_date: endDate // Optional: pass end date in 'YYYY-MM-DD' format
            }
        });


        let dailyDataArray = [];
        let thirty_day_apy = [];
        let ninety_day_apy = [];
        let correspondingDay = [];
        let dollar_value_growth = [];

        for (let i = 0; i < response.data.data.length -20; i++) {
            const record = response.data.data[i];
            dailyDataArray.push(record.portfolio_implied_apr);
            thirty_day_apy.push(record.thirty_day_apy);
            ninety_day_apy.push(record.ninety_day_apy);
            correspondingDay.push(record.timestamp);
            dollar_value_growth.push(record.dollar_value_growth);
        }

        dollar_value_growth.push(1);


        correspondingDay.push("Tue, 22 Jul 2024 23:11:49 GMT");
        // Return the processed data
        return {
            datasets1: dailyDataArray,
            datasets2: thirty_day_apy,
            datasets3: ninety_day_apy,
            datasets4: dollar_value_growth,
            labels: correspondingDay
        };
    } catch (error) {
        console.error('Error fetching portfolio track records:', error);
        throw error;
    }
};
export const fetchDailyTotalAssertData = async (date) => {
    try {
        const TEST_JWT = await fetchJWT();
        const response = await axios.get(`${ENDPOINT_BASE_URL}/track_records_daily_latest`, {
            headers: {
                'Authorization': `Bearer ${TEST_JWT}`
            },
            params: {
                date: date
            }
        });

        // Define the July 24 date as a reference point
        const filterDate = new Date('2024-07-24');

        // Filter records that are after July 24
        const filteredData = response.data.data.filter(record => new Date(record.timestamp) > filterDate);

        const totalAssetsArray = filteredData.map(record => record.total_assets_value);
        const moneyAtRiskArray = filteredData.map(record => record.net_exposure_value);
        const correspondingDay = filteredData.map(record => record.timestamp);

        return {
            datasets1: moneyAtRiskArray,
            datasets2: totalAssetsArray,
            labels: correspondingDay
        };
    } catch (error) {
        console.error('Error fetching portfolio track records:', error);
        throw error;
    }
};

export const fetchPortfolioTrackRecordsRoundFourDigit = async () => {

    try {
        const TEST_JWT = await fetchJWT();

        const response = await axios.get(`${ENDPOINT_BASE_URL}/portfolio_track_records`, {
            headers: {
                'Authorization': `Bearer ${TEST_JWT}`
            },
            params: {
                page: 1,   // Adding page query parameter
                limit: 5   // Adding limit query parameter
            }
        });

        return reformatPortfolioTrackRecordRoundFourDigit(response.data.data[0]);

    } catch (error) {
        console.error('Error fetching portfolio track records:', error);
        throw error;
    }
};

export const reformatPortfolioTrackRecordRoundFourDigit = (data) => {
    /**
     *
        * {"id":1,
    "money_at_risk_percent":"-0.7635539234855009",
    "net_exposure_value":"-0.7577440087800085",
    "portfolio_implied_30days_earnings":"0.8605773848134116",
    "portfolio_implied_apr":"0.10406109150689863",
    "timestamp":"Fri, 28 Jun 2024 04:20:33 GMT",
    "total_assets_value":"99.23909569098001"
    },
     */

    return {
        totalValue: parseFloat(data.total_assets_value).toFixed(4) * 1,
        impliedApr: (parseFloat(data.portfolio_implied_apr) * 100).toFixed(4) * 1,
        thirtyDayEarning: parseFloat(data.portfolio_implied_30days_earnings).toFixed(4) * 1,
        moneyAtRisk: parseFloat(data.money_at_risk_percent).toFixed(4) * 1,
        nev: parseFloat(data.net_exposure_value).toFixed(4) * 1,
        thirtyDayApy: (parseFloat(data.thirty_day_apy) * 100).toFixed(4) * 1,
        ninetyDayApy: (parseFloat(data.ninety_day_apy) * 100).toFixed(4) * 1,
        usdiInCirculation: ((parseFloat(data.usdi_in_circulation) ).toFixed(2) * 1),
                dollar_value_growth: ((parseFloat(data.dollar_value_growth) ).toFixed(4) * 1)

      };
};