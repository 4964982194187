import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {getAccessToken} from './authHelper';
import {jsonToCSV} from './utils';
import 'bootstrap/dist/css/bootstrap.min.css';
import './tables.css';
import styles from "./DownloadButton.module.css";
import frameIcon from "./static_files/download.svg";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import trackRecordIcon from "./static_files/Track Records.svg"

const formatDate = (dateString) => {
    const d = new Date(dateString.replace(' GMT', ''));
    const date = new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000);
    const options = {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    };

    // Format the date without the day of the week
    const formattedDate = date.toLocaleDateString('en-US', options).replace(/,/g, '');
    const formattedTime = date.toLocaleTimeString('it-IT');
    const [month, day, year] = formattedDate.split(' ');

    // Return only the date and time without the day of the week
    return `${day} ${month} ${year} ${formattedTime}`;
};

const formatToPercentage = (value) => {
    return `${(value * 100).toFixed(2)}%`;
};

const formatPercentage = (value) => {
    return `${(value * 1).toFixed(2)}%`;
};
const formatPercentageAPY = (value) => {
    return `${(value * 100).toFixed(2)}%`;
};
const formatToFourDigits = (value) => {
    return parseFloat(value).toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 4})
};
const formatUSDI = (value) => {
    return parseFloat(value).toLocaleString('en-US', {maximumFractionDigits: 4,minimumFractionDigits: 4})
};
const TrackRecords = () => {
    const [data, setData] = useState([]);
    const [initialLoading, setInitialLoading] = useState(true);
    const [remainingLoading, setRemainingLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const rowsPerPage = 60;
    const initialChunkSize = 20;
    const chunkSize = 100; // Number of rows to fetch in each subsequent request

    const fetchInitialTrackRecords = async () => {
        setRemainingLoading(false);
        try {
            const access_token = await getAccessToken();
            const trackRecordsResponse = await axios.get(`https://max1-funding-arb.uc.r.appspot.com/portfolio_track_records?page=1&limit=${initialChunkSize}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });
            setData(trackRecordsResponse.data.data);
            setInitialLoading(false);
            setTotalPages(Math.ceil(trackRecordsResponse.data.total_records / rowsPerPage));
            fetchRemainingTrackRecordsOfPage1(access_token, 1, initialChunkSize, rowsPerPage - initialChunkSize);

        } catch (error) {
            console.error('Error fetching initial track records:', error);
            setInitialLoading(false);
        }
    };

    const fetchRemainingTrackRecordsOfPage1 = async (access_token, page, offset, limit) => {
        try {
            setRemainingLoading(true);
            const response = await axios.get(`https://max1-funding-arb.uc.r.appspot.com/portfolio_track_records?page=${page}&limit=${limit}&offset=${offset}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });

            if (response.data && response.data.data && response.data.data.length > 0) {
                setData(prevData => prevData.concat(response.data.data));

                // Continue fetching remaining pages
                fetchRemainingTrackRecords(access_token, 2); // Start fetching remaining data from page 2
            } else {
                setRemainingLoading(false);
            }
        } catch (error) {
            console.error('Error fetching remaining track records:', error);
            setRemainingLoading(false);
        }
    };

    const fetchRemainingTrackRecords = async (access_token, startPage) => {
        try {
            setRemainingLoading(true);
            let currentPage = startPage;
            let remainingData = [];
            while (true) {
                const trackRecordsResponse = await axios.get(`https://max1-funding-arb.uc.r.appspot.com/portfolio_track_records?page=${currentPage}&limit=${chunkSize}`, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                });
                if (trackRecordsResponse.data.data.length === 0) break;
                remainingData = remainingData.concat(trackRecordsResponse.data.data);
                currentPage++;
            }
            const sortedData = remainingData.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
            setData(prevData => prevData.concat(sortedData));
            setRemainingLoading(false);
        } catch (error) {
            console.error('Error fetching remaining track records:', error);
            setRemainingLoading(false);
        }
    };

    const fetchUpdatedTrackRecords = async () => {
        try {
            const access_token = await getAccessToken();
            const trackRecordsResponse = await axios.get('https://max1-funding-arb.uc.r.appspot.com/portfolio_track_records', {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });
            const sortedData = trackRecordsResponse.data.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
            setData(sortedData);
        } catch (error) {
            console.error('Error fetching updated track records:', error);
        }
    };

    useEffect(() => {
        const initialize = async () => {
            const access_token = await getAccessToken();
            await fetchInitialTrackRecords(access_token);
            await runPortfolio(access_token);
        };
        initialize();
    }, []);

    useEffect(() => {
        const updatePadding = () => {
            const screenWidth = window.innerWidth;
            const paddingValue = (0.86 * screenWidth - 275) / 20;
            document.documentElement.style.setProperty('--dynamic-padding', `${paddingValue}px`);
        };

        // Call the function initially to set the padding
        updatePadding();

        // Add event listener to handle window resize
        window.addEventListener('resize', updatePadding);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', updatePadding);
        };
    }, []);
    const runPortfolio = async (access_token) => {
        try {
            setMessage("trying to run backend portfolio");
            const trackRecordsResponse2 = await axios.get('https://max1-funding-arb.uc.r.appspot.com/run_portfolio', {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });
            console.log('Portfolio response:', trackRecordsResponse2.data);
            const responseData = JSON.parse(trackRecordsResponse2.data.message);
            if (responseData.message === "Already running") {
                setMessage("The process is already running.");
                console.log("set message");
            } else if (responseData.message === "Data fetched and stored successfully") {
                setMessage("Updating data...");
                await fetchUpdatedTrackRecords();
                setMessage("Data updated.");
            } else {
                setMessage(responseData.message);
            }
        } catch (error) {
            console.error('Error running portfolio:', error);
            setMessage('Error running portfolio.');
        }
    };

    const handleDownload = () => {
        const csv = jsonToCSV(data);
        const blob = new Blob([csv], {type: 'text/csv'});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'track_records.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginatedData = data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    // for loading page gray box
    const rows = 25;
    const cells = 10;

    // When page is still loading data, display gray box
    if (initialLoading) {
        return (
            <div className="container mt-5">
                <div className=" h d-flex justify-content-between align-items-center mb-3">
                    <Header/>
                </div>
                <div className="title-box d-flex justify-content-between align-items-center">
                     <img className="green-title" src={trackRecordIcon} alt=""></img>
                </div>
                {/*{message && <div className="alert alert-info noto">{message}</div>}*/}
                <div className="table-responsive noto">
                    <table className="table table-striped table-bordered mt-3">
                    <thead className="thead-dark">
                    <tr>
                        <th>Timestamp</th>
                        <th>Total Assets Value</th>
                        <th>Portfolio Implied APR</th>
                        <th>Portfolio Implied 30 Days Earnings</th>
                        <th>Net Exposure Value</th>
                        <th>Money at Risk %</th>
                        <th>One Dollar Value Growth</th>
                        <th>Thirty Day Apy</th>
                        <th>Ninety Day Apy</th>
                        <th>USDI in Circulation</th>
                    </tr>
                    </thead>
                    <tbody>
                        {Array.from({length: rows}).map((_, rowIndex) => (
                            <tr key={rowIndex} style={{borderBottom: "1px solid #FFFFFF1A"}}>
                                {Array.from({length: cells}).map((_, cellIndex) => (
                                    <td key={cellIndex}>
                                        <div className="gray-box"></div>
                                    </td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                </table>
                </div>
                <Footer>{message}</Footer>
            </div>
        );
    }

    return (
        <div className="container mt-5">
            <div className="h d-flex justify-content-between align-items-center mb-3">
                <Header/>
            </div>
            <div className="title-box d-flex justify-content-between align-items-center">
                <img className="green-title" src={trackRecordIcon} alt=""></img>
            </div>
            <div className="table-responsive" style={{borderColor: "#1F464D"}}>
                <table className="table table-striped table-bordered mt-3">
                    <thead className="thead-dark">
                    <tr>
                        <th>Timestamp</th>
                        <th>Total Assets Value</th>
                        <th>Portfolio Implied APR</th>
                        <th>Portfolio Implied 30 Days Earnings</th>
                        <th>Net Exposure Value</th>
                        <th>Money at Risk %</th>
                        <th>One Dollar Value Growth</th>
                        <th>Thirty Day Apy</th>
                        <th>Ninety Day Apy</th>
                        <th>USDI in Circulation</th>

                    </tr>
                    </thead>
                    <tbody>
                    {paginatedData.map((record, index) => (
                        <tr key={index}>
                            <td>{formatDate(record.timestamp.replace("GMT", ""))}</td>
                            <td>{formatToFourDigits(record.total_assets_value)}</td>
                            <td>{formatToPercentage(record.portfolio_implied_apr)}</td>
                            <td>{formatToFourDigits(record.portfolio_implied_30days_earnings)}</td>
                            <td>{formatToFourDigits(record.net_exposure_value)}</td>
                            <td>{formatPercentage(record.money_at_risk_percent)}</td>
                            <td>{formatToFourDigits(record.dollar_value_growth)}</td>
                            <td>{formatPercentageAPY(record.thirty_day_apy)}</td>
                            <td>{formatPercentageAPY(record.ninety_day_apy)}</td>
                            <td>{formatUSDI(record.usdi_in_circulation)}</td>

                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            {remainingLoading && <div>Loading more data...</div>}
            <nav aria-label="Page navigation example" className="navbar-flexbox">
                <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link first" onClick={() => handlePageChange(1)}>First</button>
                    </li>
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous
                        </button>
                    </li>
                    {Array.from({length: Math.min(4, Math.ceil(data.length / rowsPerPage))}, (_, i) => i + 1).map(page => (
                        <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(page)}>{page}</button>
                        </li>
                    ))}
                    {Math.ceil(data.length / rowsPerPage) > 4 && (
                        <>
                            <li className="page-item disabled">
                                <span className="page-link">...</span>
                            </li>
                            <li className={`page-item ${currentPage === Math.ceil(data.length / rowsPerPage) ? 'active' : ''}`}>
                                <button className="page-link"
                                        onClick={() => handlePageChange(Math.ceil(data.length / rowsPerPage))}>
                                    {Math.ceil(data.length / rowsPerPage)}
                                </button>
                            </li>
                        </>
                    )}
                    <li className={`page-item ${currentPage === Math.ceil(data.length / rowsPerPage) ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
                    </li>
                    <li className={`page-item ${currentPage === Math.ceil(data.length / rowsPerPage) ? 'disabled' : ''}`}>
                        <button className="page-link last"
                                onClick={() => handlePageChange(Math.ceil(data.length / rowsPerPage))}>Last
                        </button>
                    </li>
                </ul>
                <div className={styles.parent}>
                    <div className={styles.div}>「</div>
                    <div className={styles.downloadCsvParent}>
                        <div onClick={handleDownload} className={styles.downloadCsv}>Download CSV</div>
                        <img className={styles.frameIcon} alt="" src={frameIcon}/>
                    </div>
                    <div className={styles.div1}>」</div>
                </div>
            </nav>
            <Footer>{message}</Footer>
        </div>
    );
};

export default TrackRecords;
