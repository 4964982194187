import { useProviders } from './utils/useProviders';
import { TMButton } from './TMButton';
import { ChainSelector } from './ChainSelector';
import { toNumber } from 'web3-utils';

export const WalletSelector = ({ account, wallet, chainId, onWalletChanged, isWalletConnecting }) => {
    const providers = useProviders();

    const ProviderSelector = ({ providers }) => {
        return providers.map((provider) => {
            return <TMButton 
                key={provider.info.uuid} 
                imgSrc={provider.info.icon} 
                label={provider.info.name} 
                onClick={() =>onWalletChanged(provider)} 
                disabled={isWalletConnecting || (wallet && wallet.info.uuid === provider.info.uuid)} 
                selected={wallet && wallet.info.uuid === provider.info.uuid}
            />
        });
    }

    return (
        <div className="container">
            <div class="row">
                <div class="col-md-7 col-12">
                    { providers.length === 0
                        ?   <>
                                <h2>Wallet not found</h2>
                                <p>Please install Metamask.</p>
                            </>
                        :   <>
                                <span>{ wallet ? "Switch Wallet" : "Select a wallet to use"}</span><br/>
                                <ProviderSelector providers={providers}/>
                                { wallet && <>
                                        <span>Account: {account}</span><br/>
                                        <span>ChainID: {toNumber(chainId)}</span><br/><br/>
                                    </>
                                }
                            </>
                    }
                </div>
                <div class="col-md-5 col-12">
                    { wallet && 
                        <>
                            <span>Switch Chain</span><br/>
                            <ChainSelector wallet={wallet} chainId={chainId}/>
                        </>
                    }
                </div>
            </div>
        </div>
    );
};