import React, {FunctionComponent} from 'react';
import styles from './Header.module.css';
import {NavLink} from "react-router-dom";
import menu_img from '../static_files/menu.svg';
import close_img from '../static_files/close.svg';
import logo_svg from '../static_files/logo-nobackground.svg';
import line from '../static_files/sideBarLine.svg';

const Header = () => {
    function showSidebar() {
        const sidebar = document.querySelector('.Header_sidebar__Vew4y ');
        const sideline = document.querySelector(".Header_sideline__RpfTW");
        const menu = document.querySelector(".Header_menu__Onx1a");
        // console.log("menu:",styles.menu)
        sidebar.style.display = 'flex'
        sideline.style.display = 'block'
        menu.style.display = 'none'
    }

    function closeSidebar() {
        const sidebar = document.querySelector(".Header_sidebar__Vew4y ")
        const sideline = document.querySelector(".Header_sideline__RpfTW");
        const menu = document.querySelector(".Header_menu__Onx1a");

        sidebar.style.display = 'none'
        sideline.style.display = 'none'
        menu.style.display = 'block'
    }

    return (
        <>
            <div className={styles.hideOnDesktop}>
                <ul className={styles.sideline}>

                    <img src={line} alt="" className={`absolute left-[8.6%] z-20 ${styles.menu_bar}`}></img>
                </ul>
                <ul className={styles.sidebar}>

                    <li><img onClick={closeSidebar} className={styles.close} src={close_img} alt=''></img></li>
                    <li><NavLink onClick={closeSidebar}
                                 to="/home/swap"
                                 className={({isActive}) => (isActive ? 'nav-link active-link' : 'nav-link')}

                    >
                        Swap
                    </NavLink></li>
                    <li className="sidebar-link">
                        <NavLink onClick={closeSidebar}
                                 to="/home/token-management"
                                 className={({isActive}) => (isActive ? 'nav-link active-link' : 'nav-link')}
                        >
                            Token Management
                        </NavLink></li>
                    <li className="sidebar-link">
                        <NavLink onClick={closeSidebar}
                                 to="/home/track-records"
                                 className={({isActive}) => (isActive ? 'nav-link active-link' : 'nav-link')}
                        >
                            Track Records
                        </NavLink></li>
                    <li><NavLink onClick={closeSidebar}
                                 to="/home/portfolio"
                                 className={({isActive}) => (isActive ? 'nav-link active-link' : 'nav-link')}

                    >
                        Portfolio
                    </NavLink></li>
                    <li><NavLink onClick={closeSidebar}
                                 to="/home/monitor"
                                 className={({isActive}) => (isActive ? 'nav-link active-link' : 'nav-link')}

                    >
                        Monitor
                    </NavLink></li>

                    <li><NavLink onClick={closeSidebar}
                                 to="/home/usdi-stats"
                                 className={({isActive}) => (isActive ? 'nav-link active-link' : 'nav-link')}

                    >
                        USDI Stats
                    </NavLink></li>
                </ul>
            </div>
            <a href="https://0max1.com/" target="_blank">
                <img className={styles.vectorIcon} alt="" src={logo_svg}/>
            </a>
            <ul className={styles.navBar}>
                 <li className={styles.hideOnMobile}><NavLink
                    to="/home/swap"
                    className={({isActive}) => (isActive ? 'nav-link active-link' : 'nav-link')}
                >
                    Swap
                </NavLink></li>
                <li className={styles.hideOnMobile}><NavLink
                    to="/home/token-management"
                    className={({isActive}) => (isActive ? 'nav-link active-link' : 'nav-link')}

                >
                    Token Management
                </NavLink></li>
                <li className={styles.hideOnMobile}><NavLink
                    to="/home/track-records"
                    className={({isActive}) => (isActive ? 'nav-link active-link' : 'nav-link')}

                >
                    Track Records
                </NavLink></li>
                <li className={styles.hideOnMobile}><NavLink
                    to="/home/portfolio"
                    className={({isActive}) => (isActive ? 'nav-link active-link' : 'nav-link')}

                >
                    Portfolio
                </NavLink></li>
                <li className={styles.hideOnMobile}><NavLink
                    to="/home/monitor"
                    className={({isActive}) => (isActive ? 'nav-link active-link' : 'nav-link')}

                >
                    Monitor
                </NavLink></li>

                <li className={styles.hideOnMobile}><NavLink
                    to="/home/usdi-stats"
                    className={({isActive}) => (isActive ? 'nav-link active-link' : 'nav-link')}
                >
                    USDI Stats
                </NavLink></li>
                <li className={styles.hideOnDesktop}><img onClick={showSidebar} className={styles.menu} src={menu_img}
                                                          alt=''></img></li>
            </ul>

        </>
    );
};

export default Header;
