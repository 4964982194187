// GatewayTokenVerifier.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const GatewayTokenVerifier = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { verifyGatewayToken } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    if (!token) {
      // No token present, redirect or handle as needed
      navigate('/');
      return;
    }

    (async () => {
      const isValid = await verifyGatewayToken(token);
      if (!isValid) {
        // Invalid token, redirect or show error
        navigate('/');
      } else {
        // Valid token, gateway auth is set to true in AuthContext
        setLoading(false);
      }
    })();
  }, [location, navigate, verifyGatewayToken]);

  if (loading) return <div>Loading...</div>;
  return children;
};

export default GatewayTokenVerifier;
