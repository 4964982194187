// components/Table/SmartContractTable.js
import React, { useEffect, useState } from 'react';
import { digitFormat } from "../utils";

const RawBlockchain = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to fetch data
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch('https://usdi-api-jlukzyz7wa-ew.a.run.app/raw_blockchain_transactions');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      console.log("RawBlockchain response", result);
      setData(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  // Initial data fetch
  useEffect(() => {
    fetchData();
  }, []);

  // Request to process data and fetch data again
  useEffect(() => {
    const requestRefreshData = async () => {
      try {
        const response = await fetch('https://usdi-raw-transactions-256611876551.europe-west1.run.app/process-events');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        console.log("RawBlockchain data refreshed", result);

        // Fetch data again after processing completes
        await fetchData();
      } catch (error) {
        setError(error);
      }
    };

    requestRefreshData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="table-responsive noto">
      <table className="table table-striped table-bordered mt-3">
        <thead className="thead-dark">
          <tr>
            <th>blockno</th>
            <th>date_time_utc</th>
            <th>from_address</th>
            <th>method</th>
            <th>quantity</th>
            <th>to_address</th>
            <th>token_smart_contract_address</th>
            <th>transaction_hash</th>
            <th>unix_timestamp</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>{digitFormat(row.blockno)}</td>
              <td>{row.date_time_utc}</td>
              <td>{row.from_address}</td>
              <td>{row.method}</td>
              <td>{digitFormat(row.quantity)}</td>
              <td>{row.to_address}</td>
              <td>{row.token_smart_contract_address}</td>
              <td>{row.transaction_hash}</td>
              <td>{digitFormat(row.unix_timestamp)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RawBlockchain;
