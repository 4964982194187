// Related Info: 
//   format: EIP-3085
//   rpc urls: chainlist.org & alchemy.com/node-providers/public-node
//   icons: cryptologos.cc
const chains = [
    {
        chainId: '0x1',
        chainName: 'Ethereum Mainnet',
        nativeCurrency: {
            'name': 'Ether',
            'symbol': 'ETH',
            'decimals': 18
        },
        rpcUrls: [ 
            'https://mainnet.infura.io',
            'https://eth.llamarpc.com' 
        ],
        iconUrls: [
            'https://cryptologos.cc/logos/ethereum-eth-logo.png' 
        ],
        blockExplorerUrls: [
            'https://etherscan.io'
        ]
    },
    {
        chainId: '0xa4b1',
        chainName: 'Arbitrum One',
        nativeCurrency: {
            'name': 'Ether',
            'symbol': 'ETH',
            'decimals': 18
        },
        rpcUrls: [
            'https://arbitrum.llamarpc.com'
        ],
        iconUrls: [
            'https://cryptologos.cc/logos/arbitrum-arb-logo.png' 
        ],
        blockExplorerUrls: [
            'https://arbiscan.io'
        ]
    },
    {
        chainId: '0x38',
        chainName: 'BNB Smart Chain',
        nativeCurrency: {
            'name': 'Binance Coin',
            'symbol': 'BNB',
            'decimals': 18
        },
        rpcUrls: [
            // 'https://bsc-dataseed.binance.org/',
            'https://binance.llamarpc.com'
        ],
        iconUrls: [
            'https://cryptologos.cc/logos/bnb-bnb-logo.png' 
        ],
        blockExplorerUrls: [
            'https://bscscan.com'
        ]
    },
    {
        // ref: https://docs.base.org/docs/network-information/
        //      https://github.com/base-org/brand-kit
        chainId: '0x2105',
        chainName: 'Base Mainnet',
        nativeCurrency: {
            'name': 'Ether',
            'symbol': 'ETH',
            'decimals': 18
        },
        rpcUrls: [
            'https://mainnet.base.org'
        ],
        iconUrls: [
            'https://raw.githubusercontent.com/base-org/brand-kit/refs/heads/main/logo/symbol/Base_Symbol_Blue.svg'
        ],
        blockExplorerUrls: [
            '	https://base.blockscout.com'
        ]
    },
    {
        chainId: '0xaa36a7',
        chainName: 'Sepolia Test Network',
        nativeCurrency: {
            'name': 'Ether',
            'symbol': 'ETH',
            'decimals': 18
        },
        rpcUrls: [
            'https://ethereum-sepolia-rpc.publicnode.com'
        ],
        iconUrls: [ ],
        blockExplorerUrls: [
            'https://sepolia.etherscan.io'
        ]
    }
];

export default chains;
