import React, {useState} from 'react';
import './Footer.css'; // Import the CSS file for styling
import frameIcon from '../static_files/download.svg';
import redditIcon from '../static_files/Reddit-icon.svg';
import discordIcon from '../static_files/Discord.svg';
import twitterIcon from '../static_files/x-twitter.svg';
import teleIcon from '../static_files/Telegram-icon.svg';
import styles from "../DownloadButton.module.css";
import Ellipse from '../static_files/Ellipse.svg'
import hoverReddit from '../static_files/hover-reddit.svg';
import hoverDiscord from '../static_files/hover-discord.svg';
import hoverTwitter from '../static_files/hover-x-twitter.svg';
import hoverTele from '../static_files/hover-Telegram.svg';
const Footer = (prop) => {
  const [iconSrc1, setIconSrc1] = useState('path_to_your_image/icon-x.png');
  const [iconSrc2, setIconSrc2] = useState('path_to_your_image/icon-x.png');
  const [iconSrc3, setIconSrc3] = useState('path_to_your_image/icon-x.png');
  const [iconSrc4, setIconSrc4] = useState('path_to_your_image/icon-x.png');

  return (
    <footer className="footer">
      <div className="status">
          <img className="status-indicator" alt="" src={Ellipse} />
        <span className="status-text">{prop.children}</span>
      </div>
      <div className="social-icons">
          <a href="https://x.com/0max1_" target="_blank" className="social-icon"><img className="twitter" alt="" src={twitterIcon} /></a>
          <a href="https://t.me/news_0max1"  target="_blank" className="social-icon"><img className="tele" alt="" src={teleIcon} /></a>
          <a href="https://discord.com/invite/yzccbkKxBJ"  target="_blank" className="social-icon"><img className="discord" alt="" src={discordIcon} /></a>
          <a href="https://www.reddit.com/r/0max1club/" target="_blank" className="social-icon"><img className="reddit" alt="" src={redditIcon} /></a>
      </div>
    </footer>
  );
};

export default Footer;
