import React from 'react';
import chains from './utils/chains';
import { TMButton } from './TMButton';

export const ChainSelector = ({ wallet, chainId }) => {

    async function addChain(chain) {
        try {
            await wallet.provider.request({
                method: 'wallet_addEthereumChain',
                params: [chain]
            });
        } catch (error) {
            console.error("Failed to add network:", error);
        }
    }

    async function onChainItemClicked(chain) {
        try {
            // Try switching the network
            await wallet.provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: chain.chainId }],
            });
        } catch (error) {
            if (error.code === 4902) {
                // Network not found, so try adding it
                console.log("Chain not found. Attempting to add it...");
                await addChain(chain);
            } else {
                console.error("Failed to switch network:", error);
            }
        }
    }

    function ChainList(props) {
        const chainItems = props.chains.map((chain) => {
            return <TMButton 
                key={chain.chainId} 
                imgSrc={chain.iconUrls[0]} 
                label={chain.chainName} 
                onClick={() => onChainItemClicked(chain)}
                selected={chainId === chain.chainId}
            />
        });

        return <>{chainItems}</>;
    }

    return (
        <>
            <ChainList chains={chains} />
        </>
    );
};
