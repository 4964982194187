import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext';
import HomePage from './HomePage';
import LoginPage from './LoginPage';
import Swap from './tables/Swap';
import GatewayTokenVerifier from './GatewayTokenVerifier';

const AdminProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to="/" />;
};

const GatewayProtectedRoute = ({ children }) => {
  const { isGatewayAuthenticated } = useAuth();
  return isGatewayAuthenticated ? children : <Navigate to="/" />;
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Admin login route */}
          <Route path="/" element={<LoginPage />} />

          {/* Gateway route: verify token before showing Swap */}
          <Route
            path="/swap"
            element={
              <GatewayTokenVerifier>
                <GatewayProtectedRoute>
                  <Swap />
                </GatewayProtectedRoute>
              </GatewayTokenVerifier>
            }
          />

          {/* Admin protected routes */}
          <Route
            path="/home/*"
            element={
              <AdminProtectedRoute>
                <HomePage />
              </AdminProtectedRoute>
            }
          />

          {/* Catch-all redirect */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
