import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getAccessToken } from '../authHelper';
import "./USDIStats.css"
function UsersTable() {
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalStep, setModalStep] = useState('form'); // 'form' or 'confirm'
  const [selectedUserEmail, setSelectedUserEmail] = useState('');
  const [selectedAddressType, setSelectedAddressType] = useState('');
  const [inputAddress, setInputAddress] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const access_token = await getAccessToken();
        const response = await axios.get(
          'https://max1-funding-arb.uc.r.appspot.com/check_tables',
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );

        if (response.data && response.data.users) {
          setUsers(response.data.users);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Opens the modal for editing a particular user's address type
  const handleAddressClick = (email, addressType) => {
    setSelectedUserEmail(email);
    setSelectedAddressType(addressType);
    setInputAddress('');
    setModalStep('form');
    setShowModal(true);
  };

  // After user enters new address and clicks "Add wallet"
  const handleAddWalletClick = () => {
    if (!inputAddress.trim()) return;
    // Move to confirmation step
    setModalStep('confirm');
  };

  // Send the POST request to add the single chosen wallet address
  const handleConfirmAddWallet = async () => {
    try {
      const access_token = await getAccessToken();
      const requestData = {
        email: selectedUserEmail,
        [selectedAddressType]: inputAddress
      };

      // This requestData only contains email and one specific address type field.
      await axios.post(
        'https://max1-funding-arb.uc.r.appspot.com/add_wallet',
        requestData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      // Close modal on success (you could also refresh data if needed)
      setShowModal(false);
    } catch (error) {
      console.error('Error adding wallet:', error);
      // You can handle the error by showing a message in the modal if desired
    }
  };

  // Determine address fields for the clickable cells
  const addressFields = [
    'ethereum_receive_address',
    'bsc_receive_address',
    'tron_receive_address',
    'solana_receive_address',
    'wire_info'
  ];

  return (
    <div>
      {/* Second table */}
      <div className="table-responsive noto">
        <table className="table table-striped table-bordered mt-3">
          <thead className="thead-dark">
            <tr>
              <th>Email</th>
              <th>Ethereum Receive Address</th>
              <th>BSC Receive Address</th>
              <th>Tron Receive Address</th>
              <th>Solana Receive Address</th>
              <th>Wire Info</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <td>{user.email || ''}</td>
                {addressFields.map((field) => (
                  <td
                    key={field}
                    className="clickable-cell"
                    onClick={() => handleAddressClick(user.email, field)}
                    title="Click to add/change address to this wallet"
                    style={{ cursor: 'pointer' }}
                  >
                    {user[field] || ''}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="modal d-block" tabIndex="-1" role="dialog" style={{ background: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              {modalStep === 'form' && (
                <>
                  <div className="modal-header">
                    <h5 className="modal-title">Add Address for {selectedUserEmail}</h5>
                    {/*<button type="button" className="close" onClick={() => setShowModal(false)}>*/}
                    {/*  <span>&times;</span>*/}
                    {/*</button>*/}
                  </div>
                  <div className="modal-body">
                    <p>Address Type: {selectedAddressType}</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter new address"
                      value={inputAddress}
                      onChange={(e) => setInputAddress(e.target.value)}
                    />
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
                      Close
                    </button>
                    <button type="button" className="btn btn-primary" onClick={handleAddWalletClick}>
                      Add wallet
                    </button>
                  </div>
                </>
              )}

              {modalStep === 'confirm' && (
                <>
                  <div className="modal-header">
                    <h5 className="modal-title">Confirm Wallet Addition</h5>
                    {/*<button type="button" className="close" onClick={() => setShowModal(false)}>*/}
                    {/*  <span>&times;</span>*/}
                    {/*</button>*/}
                  </div>
                  <div className="modal-body">
                    <p>Email: {selectedUserEmail}</p>
                    <p>Type: {selectedAddressType}</p>
                    <p>New Address: {inputAddress}</p>
                    <p>Please confirm to add this wallet address.</p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
                      Cancel
                    </button>
                    <button type="button" className="btn btn-success" onClick={handleConfirmAddWallet}>
                      Confirm
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UsersTable;
