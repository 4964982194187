import React, { useState, useEffect } from 'react';
import ERC20ABI from './utils/ERC20Abi.json';
import DistributorABI from './utils/ERC20TokenDistributorAbi.json';
import getDistributorAddr from './utils/distributor';
import { isAddress } from 'web3-validator';
import { toNumber, toBigInt, toHex, toDecimal } from 'web3-utils';

export const TokenDistibutor = ({web3, account, chainId}) => {
    const [isFetchingToken, setIsFetchingToken] = useState(false);
    const [isWaitingWallet, setIsWaitingWallet] = useState(false);
    const [addr, setAddr] = useState(null);
    const [tokenInfo, setTokenInfo] = useState(null);
    const [csvFilename, setCSVFilename] = useState('');
    const [csvInfo, setCSVInfo] = useState(null);
    const [transHash, setTransHash] = useState('');

    function handleTokenFindSubmit(e) {
        e.preventDefault();
        const input = e.target.address.value;
        if (isAddress(input)) {
            findToken(input);
        } else {
            alert("Invalid etherum address");
        }
    }

    function handleTokenUpdateSumbit(e) {
        e.preventDefault();
        updateToken();
    }

    const handleCSVPanelClicked = (e) => {
        setTransHash('');
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = '.csv';
        fileInput.onchange = (event) => {
            const file = event.target.files[0];
            if (file) {
                setCSVFilename(file.name);

                const reader = new FileReader();
                reader.onload = (e) => {
                    const content = e.target.result;
                    if (!readCSV(content, toNumber(tokenInfo.decimals))) {
                        setCSVFilename('');
                    }
                };
                reader.readAsText(file);
            }
        };
        fileInput.click();
    };

    const handleCSVRemoveClicked = (e) => {
        setCSVFilename('');
        setCSVInfo(null);
    }

    async function handleAllowanceClicked(e) {
        e.preventDefault();
        setIsWaitingWallet(true);
        const token = new web3.eth.Contract(ERC20ABI, addr);
        try {
            const result = await token.methods.approve(getDistributorAddr(chainId), csvInfo.total - tokenInfo.allowance).send({from: account});
            if (result) { updateToken(); }
        } catch (error) {
            // User denied
            if (!error || !error.cause || error.cause.code !== 4001) {
                alert('Your approve request failed :(');
                console.log(error);
            }
        }
        setIsWaitingWallet(false);
    }

    async function handleDistributeClicked(e) {
        e.preventDefault();
        setIsWaitingWallet(true);
        const distributor = new web3.eth.Contract(DistributorABI, getDistributorAddr(chainId));
        try {
            const result = await distributor.methods.distribute(addr, csvInfo.recipients, csvInfo.amounts).send({from: account});
            if (result && result.transactionHash) {
                setCSVFilename('');
                setCSVInfo(null);
                updateToken();
                setTransHash(result.transactionHash);
            }
        } catch (error) {
            // User denied
            if (!error || !error.cause || error.cause.code !== 4001) {
                alert('Your distribute request failed :(');
                console.log(error);
            }
        }
        setIsWaitingWallet(false);
    }

    function handleTransHashClicked(e) {
        e.preventDefault();
            navigator.clipboard.writeText(transHash).then(() => {
        }).catch(err => {
            alert(`Copying transaction hash to clipboard failed. The hash is ${transHash}`);
            console.error('Error copying text to clipboard:', err);
        });
    }

    function toDecimalString(val, decimals) {
        const dec_i = toNumber(decimals);
        const val_b = toBigInt(val);
        const div_b = toBigInt(10) ** toBigInt(decimals);

        const inte = val_b / div_b;
        const frac = val_b % div_b;

        let fracStr = frac.toString().padStart(dec_i, '0').replace(/0+$/, '');

        return fracStr ? `${inte.toString()}.${fracStr}` : inte.toString();
    }

    // returns true if the csv is valid
    // else create an alert indicating what is wrong
    function readCSV(csvStr, maxDecimals) {
        function alertWithMsgAndFormat(msg) {
            alert(`Format: address(0x....),amount - max 200 entries\nError: ${msg}`);
            return false;
        }

        if (!csvStr || typeof csvStr !== 'string') {
            return alertWithMsgAndFormat('Invalid file content');
        }

        const rows = csvStr.trim().split('\n');
        if (rows.length === 0) {
            return alertWithMsgAndFormat('CSV file is empty');
        }

        const ethAddressRegex = /^0x[a-fA-F0-9]{40}$/;
        let recipients = [];
        let amounts = [];
        let sum = toBigInt(0);
        for (let i = 0; i < rows.length; i++) {
            const row = rows[i].trim();
            if (!row) { continue; }

            const cols = row.split(',');
            if (cols.length !== 2) {
                return alertWithMsgAndFormat(`Row ${i + 1} does not have exactly two columns`);
            }

            const [addr, value] = cols.map(col => col.trim());
            if (!ethAddressRegex.test(addr)) {
                return alertWithMsgAndFormat(`Row ${i + 1} has an invalid Ethereum address.`);
            }
          
            let valueNum = parseFloat(value);
            if (isNaN(valueNum)) {
                return alertWithMsgAndFormat(`Row ${i + 1} has an invalid value.`);
            }
            const [int, frac = ""] = value.split('.');
            if((frac ? frac.length : 0) > maxDecimals) {
                return alertWithMsgAndFormat(`Row ${i + 1} has a value with too many decimals.`);
            }
            valueNum = toBigInt(int + frac.padEnd(maxDecimals, '0').slice(0, maxDecimals));
            sum += valueNum;

            recipients.push(addr);
            amounts.push(valueNum);
        }

        if (recipients.length > 200) {
            return alertWithMsgAndFormat(`CSV has too many entries (${recipients.length}).`);
        }
        setCSVInfo({
            recipients: recipients,
            amounts: amounts,
            total: sum
        });
        return true;
    }

    async function findToken(addr) {
        setIsFetchingToken(true);
        const token = new web3.eth.Contract(ERC20ABI, addr);

        try {
            const tokenInfo = {
                name: await token.methods.name().call(),
                symbol: await token.methods.symbol().call(),
                decimals: await token.methods.decimals().call(),
                balance: await token.methods.balanceOf(account).call(),
                allowance: await token.methods.allowance(account, getDistributorAddr(chainId)).call()
            }
            setAddr(addr);
            setTokenInfo(tokenInfo);
        } catch (error) {
            setAddr(null);
            setTokenInfo(null);
            console.log(error);
        }
        setIsFetchingToken(false);
    }

    async function updateToken() {
        if (!addr) { return; }
        setIsFetchingToken(true);
        const token = new web3.eth.Contract(ERC20ABI, addr);
        try {
            const newTokenInfo = {
                name: tokenInfo.name,
                symbol: tokenInfo.symbol,
                decimals: tokenInfo.decimals,
                balance: await token.methods.balanceOf(account).call(),
                allowance: await token.methods.allowance(account, getDistributorAddr(chainId)).call()
            }
            setTokenInfo(newTokenInfo);
        } catch (error) {
            setAddr(null);
            setTokenInfo(null);
            console.log(error);
        }
        setIsFetchingToken(false);
    }

    const TokenFinder = () => {
        const handleChange = (e) => {
            const input = e.target.value;
            e.target.value = input.replace(/[^a-zA-Z0-9]/g, '');
        };

        return (
            <form onSubmit={handleTokenFindSubmit}>
                <div>
                    <input name="address" class="text-white py-2 px-2" style={{width: "45ch"}} placeholder="Token Address (0x....)" onChange={handleChange}/>
                </div>
                <button type="submit" class="text-white py-2" style={{width: "45ch"}} disabled={!account||!getDistributorAddr(chainId)||isFetchingToken}>
                    Find
                </button>
            </form>
        )
    };

    const TokenDisplay = ({ tokenInfo, csvInfo, transHash }) => {
        return (
            <>
                Token: {tokenInfo ? <>{tokenInfo.name}, {toNumber(tokenInfo.decimals)} decimals</> : "N/A"}<br/>
                Your Balance: { tokenInfo ? 
                    <>
                        {toDecimalString(tokenInfo.balance, tokenInfo.decimals)} {tokenInfo.symbol}
                    </> : "N/A"
                }
                <br/>
                Unspent Allowance: { tokenInfo ? 
                    <>
                        {toDecimalString(tokenInfo.allowance, tokenInfo.decimals)} {tokenInfo.symbol}
                    </> : "N/A"
                }
                <br/>
                <button onClick={handleTokenUpdateSumbit} class="text-white py-2" style={{width: "45ch"}} disabled={!account||isFetchingToken||!addr}>Update</button>
            </>
        )
    };

    const TokenDistribute = ({}) => {
        const CSVPanel = ({ handleCSVPanelClicked }) => {
            return (
                <div>
                    <div>
                        <button class="text-white py-2 px-2" onClick={handleCSVPanelClicked} disabled={!account||!addr}>
                            Upload CSV
                        </button>
                        {
                            csvFilename && <span>: {csvFilename} <a href="#" className="text-white text-decoration-underline"  onClick={handleCSVRemoveClicked}>(remove)</a></span>
                        }
                    </div>
                </div>
            )
        };
    
        const AllowancePanel = ({ tokenInfo, csvInfo }) => {
            const AllowanceText = ({}) => {
                if (tokenInfo.allowance >= csvInfo.total) {
                    return <>Nothing to do</>
                } else if (tokenInfo.balance < csvInfo.total) {
                    return <>No enough {tokenInfo.symbol}</>
                } else {
                    return <>{toDecimalString(csvInfo.total - tokenInfo.allowance, tokenInfo.decimals)} {tokenInfo.symbol}</>
                }
            };
    
            return (
                <div>
                    <button class="text-white py-2 px-2" onClick={handleAllowanceClicked} disabled={!account||!addr||isWaitingWallet||tokenInfo.balance < csvInfo.total||tokenInfo.allowance>=csvInfo.total}>
                        Approve
                    </button>
                    <span>: <AllowanceText/></span>
                </div>
            );
        };
    
        const DistributePanel = ({ tokenInfo, csvInfo }) => {
            return (
                <div>
                    <button class="text-white py-2 px-2" onClick={handleDistributeClicked} disabled={!account||!addr||isWaitingWallet||tokenInfo.balance < csvInfo.total||tokenInfo.allowance<csvInfo.total}>
                        Send
                    </button>
                    <span>: {toDecimalString(csvInfo.total, tokenInfo.decimals)} {tokenInfo.symbol}</span>
                </div>
            );
        }
    
        const TransHashPanel = ({ transHash }) => {
            return (
                <>
                    <hr/>
                    <span>Success!<br/>Save hash before new upload</span>
                    <button className="text-white py-1 px-1" onClick={handleTransHashClicked}>Copy Hash</button><br/>
                </>
            );
        }

        return (
            <>
                { tokenInfo && <CSVPanel handleCSVPanelClicked={handleCSVPanelClicked}/>}
                { tokenInfo && csvInfo && (
                    <>
                        <AllowancePanel tokenInfo={tokenInfo} csvInfo={csvInfo}/> 
                        <DistributePanel tokenInfo={tokenInfo} csvInfo={csvInfo}/>
                    </>
                )}
                { transHash && <TransHashPanel transHash={transHash}/>}
            </>
        );
    };

    useEffect(() => {
        if (!account) { return; }
        updateToken();
    }, [account]);

    useEffect(() => {
        if (!getDistributorAddr(chainId)) {
            setAddr('');
            setTokenInfo(null);
            setCSVFilename('');
            setCSVInfo(null);
        }
        updateToken();
    }, [chainId]);

    return (
        <div className="container">
            <h4>Distribute your ERC20 Token {!getDistributorAddr(chainId) && (<>(Unsupported Chain)</>)}</h4>
            <br/>
            <div className="row">
                <div className="col-md-7 col-12">
                    <TokenFinder/><br/>
                    <TokenDisplay tokenInfo={tokenInfo}/>
                </div>
                <div className="col-md-5 col-12">
                    <TokenDistribute tokenInfo={tokenInfo} csvInfo={csvInfo} transHash={transHash}/>
                </div>
            </div>
        </div>
    )
}
