import axios from 'axios';

const API_BASE_URL = 'https://max1-funding-arb.uc.r.appspot.com';

export const login = async (username, password) => {
  const response = await axios.post(`${API_BASE_URL}/login`, { username, password });
  const { access_token, refresh_token } = response.data;
  localStorage.setItem('accessToken', access_token);
  localStorage.setItem('refreshToken', refresh_token);
  localStorage.setItem('tokenExpiry', Date.now() + 2400 * 1000); 
};

export const loginWithStoredCredentials = async () => {
  const username = 'admin'; // Change as needed
  const password = 'admin'; // Change as needed
  await login(username, password);
};

export const refreshToken = async () => {

  const refresh_token = localStorage.getItem('refreshToken');
  const response = await axios.post(`${API_BASE_URL}/refresh`, { refresh_token });
  const { access_token } = response.data;
  localStorage.setItem('accessToken', access_token);
  localStorage.setItem('tokenExpiry', Date.now() + 2400 * 1000); // Reset expiry to 1 hour
};

export const getAccessToken = async () => {
  const accessToken = localStorage.getItem('accessToken');
  if (!accessToken) {
    await loginWithStoredCredentials();
  }
  const expiry = localStorage.getItem('tokenExpiry');
  if (Date.now() > expiry) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('tokenExpiry');
    localStorage.setItem('isAuthenticated', JSON.stringify(false));
    window.location.href = '/'; // Redirect to the home page
    return null;
  }
  return localStorage.getItem('accessToken');
};
