import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import {getAccessToken} from './authHelper';
import {jsonToCSV} from './utils';
import 'bootstrap/dist/css/bootstrap.min.css';
import './tables.css';
import styles from "./DownloadButton.module.css";
import frameIcon from "./static_files/download.svg";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import portfolioIcon from "./static_files/Current Portfolio.svg";
import potentialPortfolioIcon from "./static_files/Potential Portfolio.svg";

const formatToPercentage = (value) => {
    return `${(value * 100).toFixed(2)}%`;
};
const formatWithDollarSign = (value) => {
    if (isNaN(value)) {
        return NaN;
    }
    return parseFloat(value).toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 4});
};
const formatDate = (dateString) => {
    const d = new Date(dateString.replace(' GMT', ''));
    const date = new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000);
    const options = {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    };

    // Format the date without the day of the week
    const formattedDate = date.toLocaleDateString('en-US', options).replace(/,/g, '');
    const formattedTime = date.toLocaleTimeString('it-IT');
    const [month, day, year] = formattedDate.split(' ');

    // Return only the date and time without the day of the week
    return `${day} ${month} ${year} ${formattedTime}`;
};


const fetchPortfolioData = async (assetType = 'all', access_token) => {
    try {
        const response = await axios.get(`https://max1-funding-arb.uc.r.appspot.com/portfolio_latest?asset_type=${assetType}`, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error(`Error fetching portfolio for asset type ${assetType}:`, error);
        return [];
    }
};


const Portfolio = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [remainingLoading, setRemainingLoading] = useState(false);
    const initialLoad = useRef(true); // To track initial load
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 600);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth > 600);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const fetchPortfolio = async (access_token) => {
        try {
            setLoading(true);
            setRemainingLoading(true);
            const cryptoData = await fetchPortfolioData('Crypto', access_token);
            setData(cryptoData);
            setLoading(false);
            const positionData = await fetchPortfolioData('Position', access_token);
            setData(prevData => [...prevData, ...positionData]);
            const naData = await fetchPortfolioData('nan', access_token);
            setData(prevData => [...prevData, ...naData]);

            setRemainingLoading(false);
        } catch (error) {
            console.error('Error fetching portfolio:', error);
            setLoading(false);
        }
    };

    const fetchUpdatedPortfolio = async (access_token) => {
        try {

            const trackRecordsResponse = await axios.get('https://max1-funding-arb.uc.r.appspot.com/portfolio_latest', {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });
            setData(trackRecordsResponse.data);
        } catch (error) {
            console.error('Error fetching updated portfolio:', error);
        }
    };

    useEffect(() => {
        if (initialLoad.current) {
            const initialize = async () => {
                const access_token = await getAccessToken();
                await fetchPortfolio(access_token);
                await runPortfolio(access_token);
            };
            initialize();
            initialLoad.current = false; // Set to false after the initial fetch
        }
    }, []);

    const runPortfolio = async (access_token) => {
        try {
            setMessage("trying to run backend portfolio");
            const trackRecordsResponse2 = await axios.get('https://max1-funding-arb.uc.r.appspot.com/run_portfolio', {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });
            console.log('Portfolio response:', trackRecordsResponse2.data);
            const responseData = JSON.parse(trackRecordsResponse2.data.message);
            if (responseData.message === "Already running") {
                setMessage("The process is already running.");
                console.log("set message");
            } else if (responseData.message === "Data fetched and stored successfully") {
                setMessage("Data fetched and stored successfully. Updating data...");
                await fetchUpdatedPortfolio(access_token);
                setMessage("Data updated.");
            } else {
                setMessage(responseData.message);
            }
        } catch (error) {
            console.error('Error running portfolio:', error);
            setMessage('Error running portfolio.');
        }
    };

    const handleDownload = () => {
        const csv = jsonToCSV(data);
        const blob = new Blob([csv], {type: 'text/csv'});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'portfolio_latest.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    // for loading page gray box
    const rows = 6;
    const cells = 9;
    const rows2 = 7;
    const cells2 = 4;
    // When page is still loading data, display gray box
    if (loading) {
        return (
            <div className="container mt-5">
                <div className="h d-flex justify-content-between align-items-center mb-3">
                    <Header/>
                </div>
                <div className="title-box d-flex justify-content-between align-items-center">
                    <img className="green-title" src={portfolioIcon} alt=""></img>
                </div>
                <div className="table-responsive portfolio-table">
                    <table className="table table-striped table-bordered mt-3">
                        <thead className="thead-dark">
                        <tr>
                            <th className="current-profolio">Symbol</th>
                            <th className="current-profolio">Assets Types</th>
                            <th className="current-profolio">Amount</th>
                            <th className="current-profolio">Mark Price</th>
                            <th className="current-profolio">Assets Value</th>
                            <th className="current-profolio">Portfolio Allocation</th>
                            <th className="current-profolio">Implied APR</th>
                            <th className="current-profolio">Target Allocation Percent</th>
                            <th className="current-profolio">Order Value Required</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Array.from({length: rows}).map((_, rowIndex) => (
                            <tr key={rowIndex} style={{borderBottom: "1px solid #FFFFFF1A"}}>
                                {Array.from({length: cells}).map((_, cellIndex) => (
                                    <td key={cellIndex} //style={{paddingTop:'23px', paddingBottom:'23px'}}
                                    >
                                        <div className="gray-box"></div>
                                    </td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <img className="green-title potential-profolio" src={potentialPortfolioIcon} alt=""></img>
                </div>
                <div className="table-responsive potential-table">
                    <table className="table table-striped table-bordered mt-3">
                        <thead className="thead-dark">
                        <tr>
                            <th className="first-row title">Symbol</th>
                            <th className="title">Implied APR</th>
                            <th className="title">Target Allocation Percent</th>
                            <th className="title">Order Value Required</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Array.from({length: rows2}).map((_, rowIndex) => (
                            <tr key={rowIndex} style={{borderBottom: "1px solid #FFFFFF1A"}}>
                                {Array.from({length: cells2}).map((_, cellIndex) => (
                                    <td key={cellIndex}>
                                        <div className="gray-box"></div>
                                    </td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <Footer>{message}</Footer>
            </div>
        );
    }

    // sort_data based on symbol
    const sortData = (data) => {
        if (data.length !== 0) {
            data.sort((a, b) => a.symbol.localeCompare(b.symbol));
            return data
        } else {
            return data;
        }
    }


    return (
        <div className="container mt-5">
            <div className="h d-flex justify-content-between align-items-center mb-3">
                <Header/>
            </div>
            <div className="title-box d-flex justify-content-between align-items-center">
                <img className="green-title" src={portfolioIcon} alt=""></img>
                {data[0] != null && (
                    <div className="time-stamp">
                        {formatDate(data[0].utc_timestamp)}
                    </div>
                )}

            </div>
            <div className="table-responsive portfolio-table">
                <table className="table table-striped table-bordered mt-3">
                    <thead className="thead-dark">
                    <tr>
                        <th className="current-profolio">Symbol</th>
                        <th className="current-profolio">Assets Types</th>
                        <th className="current-profolio">Amount</th>
                        <th className="current-profolio">Mark Price</th>
                        <th className="current-profolio">Assets Value</th>
                        <th className="current-profolio">Portfolio Allocation</th>
                        <th className="current-profolio">Implied APR</th>
                        <th className="current-profolio">Target Allocation Percent</th>
                        <th className="current-profolio">Order Value Required</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data && data.length && sortData(data.filter(record => !isNaN(record.amount))).map((record, index) => {
                        // Check if the current symbol is a substring of the next symbol
                        const sortedData = sortData(data.filter(record => !isNaN(record.amount)));
                        const isSubSymbol = index < sortedData.length - 1 && sortedData[index + 1].symbol.includes(record.symbol);
                        const isParentSymbol = index > 0 && record.symbol.includes(sortedData[index - 1].symbol);
                        const isNegative = record.amount < 0;

                        // changed padding if grouped
                        const group = {
                            paddingBottom: (isSubSymbol && window.innerWidth > 600) ? "3px" : "30px",
                            paddingTop: (isParentSymbol && window.innerWidth > 600) ? "3px" : "30px",
                        }
                        // try to aling negative with positive
                        const alignNegative = {
                            paddingLeft: (isNegative) ? "11px" : "20px",
                        }
                        return (
                            <tr
                                key={index}
                                style={{borderBottom: isSubSymbol ? "1px solid #FFFFFF1A" : "1px solid #FFFFFF4D"}}
                            >
                                <td className="current-profolio" style={group}>{record.symbol}</td>
                                <td className="current-profolio" style={group}>{record.assets_types}</td>
                                <td className="current-profolio" style={{...group, ...alignNegative,color:(+record.amount === 0 ) ? '#FFFFFF4D' : '#FFFFFFCC'
                                }}>
                                    {formatWithDollarSign(record.amount).replace("$", "")}
                                </td>

                                <td className="current-profolio"
                                    style={{
                                        ...group,
                                        color: (+record.mark_price === 0) ? '#FFFFFF4D' : '#FFFFFFCC',
                                    }}>{formatWithDollarSign(record.mark_price)}</td>
                                <td className="current-profolio"
                                    style={{...group, ...alignNegative, color:(+record.assets_value === 0 ) ? '#FFFFFF4D' : '#FFFFFFCC',
                                    }}>{formatWithDollarSign(record.assets_value)}</td>
                                <td className="current-profolio"
                                    style={{...group, ...alignNegative, color:(+record.portfolio_allocation === 0) ? '#FFFFFF4D' : '#FFFFFFCC',
                                    }}>{formatToPercentage(record.portfolio_allocation)}</td>
                                <td className="current-profolio"
                                    style={{
                                        ...group,
                                        color:(+record.implied_apr === 0 ) ? '#FFFFFF4D' : '#FFFFFFCC',
                                    }}>{formatToPercentage(record.implied_apr)}</td>
                                <td className="current-profolio"
                                    style={{
                                        ...group,
                                        color: (+record.target_allocation_percent === 0 ) ? '#FFFFFF4D' : '#FFFFFFCC',
                                    }}>{formatToPercentage(record.target_allocation_percent)}</td>

                                <td className="current-profolio"
                                    style={{
                                        ...group,
                                        color:(+record.order_value_required === 0 ) ? '#FFFFFF4D' : '#FFFFFFCC',
                                    }}>{formatWithDollarSign(record.order_value_required)}</td>
                            </tr>
                        );
                    })}
                    </tbody>

                </table>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <img className="green-title potential-profolio" src={potentialPortfolioIcon} alt=""></img>
            </div>
            <div className="table-responsive potential-table">
                <table className="table table-striped table-bordered mt-3">
                    <thead className="thead-dark">
                    <tr>
                        <th className="first-row title">Symbol</th>
                        <th className="title">Implied APR</th>
                        <th className="title">Target Allocation Percent</th>
                        <th className="title">Order Value Required</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data && data.length && data
                        .filter(record => isNaN(record.amount)).map((record, index) => (
                            <tr key={index}>
                                <td className="first-row potential-data">{record.symbol}</td>
                                <td style={{
                                        color:(+record.implied_apr === 0 ) ? '#FFFFFF4D' : '#FFFFFFCC',
                                    }}className="first-row potential-data">{formatToPercentage(record.implied_apr)}</td>
                                <td style={{
                                        color:(+record.target_allocation_percent === 0 ) ? '#FFFFFF4D' : '#FFFFFFCC',
                                    }}className="first-row potential-data">{formatToPercentage(record.target_allocation_percent)}</td>
                                <td style={{
                                        color:(+record.order_value_required === 0) ? '#FFFFFF4D' :'#FFFFFFCC',
                                    }}className="first-row potential-data">{formatWithDollarSign(record.order_value_required)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {remainingLoading && <div>Loading more data...</div>}
            <div className={styles.parent}>
                <div className={styles.div}>「</div>
                <div className={styles.downloadCsvParent}>
                    <div onClick={handleDownload} className={styles.downloadCsv}>Download CSV</div>
                    <img className={styles.frameIcon} alt="" src={frameIcon}/>
                </div>
                <div className={styles.div1}>」</div>
            </div>
            <Footer>{message}</Footer>
        </div>
    );
};

export default Portfolio;
