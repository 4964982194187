import React, { useState } from 'react';
import { Web3 } from 'web3';
import { toHex } from 'web3-utils';
import Footer from './footer/Footer';
import Header from './header/Header';
import { WalletSelector } from './token_management/WalletSelector';
import { TokenCreator } from './token_management/TokenCreator';
import { TokenDistibutor } from './token_management/TokenDistributor';

export function TokenManagement() {
    // app state
    const [web3, setWeb3] = useState(undefined);
    const [wallet, setWallet] = useState(undefined);  // EIP-6963 provider like window.ethereum
    const [account, setAccount] = useState(undefined);
    const [chainId, setChainId] = useState(undefined); // hex
    const [isWalletConnecting, setIsWalletConnecting] = useState(false);

    const onAccountsChanged = (accounts) => {
        if (accounts.length !== 0) {
            setAccount(accounts[0]);
            return;
        }

        setWeb3(undefined);
        setWallet(undefined);
        setAccount(undefined);
        setChainId(undefined);
    }

    const onChainChanged = (chainId) => {
        // different wallet pass in different type (hex, number, etc.)
        // convert and store hex string
        if (typeof chainId !== 'string') {
            chainId = toHex(chainId);
        }
        setChainId(chainId);
    }

    async function onWalletChanged(wallet) {
        setIsWalletConnecting(true);
        const newWeb3 = new Web3(wallet.provider);
        try {
            const accounts = await newWeb3.eth.requestAccounts();
            let chainId = await newWeb3.eth.getChainId();
            setWeb3(newWeb3);
            setWallet(wallet);
            onAccountsChanged(accounts);
            onChainChanged(chainId)
            wallet.provider.on('accountsChanged', onAccountsChanged);
            wallet.provider.on('chainChanged', onChainChanged);
            console.log('wallet', wallet);
        } catch (error) {
            console.log(error);
        }
        setIsWalletConnecting(false);
    }

    return (
        <div className="container mt-5">
            <div className="h d-flex justify-content-between align-items-center mb-3">
                <Header/>
            </div>
            <WalletSelector
                account={account} 
                wallet={wallet}
                chainId={chainId}
                onWalletChanged={onWalletChanged} 
                isWalletConnecting={isWalletConnecting}
            />
            <hr/>
            <TokenDistibutor web3={web3} account={account} chainId={chainId}/>
            <hr/>
            <TokenCreator web3={web3} account={account}/>
            <div className="title-box d-flex justify-content-between align-items-center">
                    <img className="green-title" src="" alt=""></img>
            </div>
            <Footer>Under Development</Footer>
        </div>
    )
}