import React, { useState } from 'react';
import styles from './UserHeader.module.css';
import logo_svg from '../static_files/logo-nobackground.svg';
import user_icon from '../static_files/Subtract.svg';
import { useAuth } from '../../AuthContext';
import close_img from "../static_files/close.svg";

const UserHeader = () => {
  const [showPanel, setShowPanel] = useState(false);
  const { isGatewayAuthenticated, gatewayUserData, logout } = useAuth() || {};

  const handleUserIconClick = () => {
    setShowPanel((prev) => !prev);
  };

  const closeSidebar = () => {
    setShowPanel(false);
  };

  const handleLogoutClick = () => {
    // Call logout from auth context
    logout();
    // Optionally close the panel
    // setShowPanel(false);
    // Redirect to gateway
    // window.location.href = "https://gateway.0max1.com/";
  };

  return (
      <div className={styles.outerContainer}>
    <div className={styles.container}>
      <a href="https://0max1.com/" target="_blank" rel="noreferrer">
        <img className={styles.vectorIcon} alt="" src={logo_svg}/>
      </a>

      <div className={styles.userIconContainer}>
        <img
          className={styles.userIcon}
          alt="User Icon"
          src={user_icon}
          onClick={handleUserIconClick}
        />

        <div className={`${styles.sidePanel} ${showPanel ? styles.open : ''}`}>
          <img onClick={closeSidebar} className={styles.close} src={close_img} alt="Close" />
          {isGatewayAuthenticated && gatewayUserData ? (
            <div className={styles.userInfo}>
              <h3>User Information</h3>
              <p>Email: {gatewayUserData.email}</p>
              <button className={styles.logoutBtn} onClick={handleLogoutClick}>
                Logout
              </button>
            </div>
          ) : (
            <div className={styles.userInfo}>
              <h3>No User Information</h3>
              <div className={styles.logoutBtnWrapper}>
              <button className={styles.logoutBtn} onClick={handleLogoutClick}>
                Logout
              </button>
                </div>
            </div>
          )}
        </div>
      </div>
    </div>
        </div>
  );
};

export default UserHeader;
