"use client";
import React, {useState, useEffect} from "react";

import styles from "./mev.module.css";
import {
    Chart as ChartJs,
    ArcElement,
    Tooltip,
    Legend,
    Colors,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Filler,
    TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";
import "chartjs-plugin-style";
import { Line} from "react-chartjs-2";
import {useLineData, getLineOptions, verticalLinePlugin} from "./LineConfig";
// import "../../utils/number-processor";
import {
    fetchPortfolioTrackRecordsRoundFourDigit
} from "./fetchDailyPortfolioData";

ChartJs.register(
    ArcElement,
    Tooltip,
    Legend,
    Colors,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Filler,
    TimeScale
);



const LineChart = ({trackerData}) => {
    const [lineOptions, setLineOptions] = useState({});
    const [pieData, setPieData] = useState(null);
    const [pieOptions, setPieOptions] = useState(null);
    const [dollarData, setDollarData] = useState(null)
    const lineChartData = useLineData();

    useEffect(() => {
        const fetchData = async () => {
            try {
                    [trackerData] = await Promise.all([
                        fetchPortfolioTrackRecordsRoundFourDigit(),
                    ]);
                    setDollarData((trackerData.dollar_value_growth - 1) / 0.01)
                // done for line data
                setPieData(pieData);
                setPieOptions(pieOptions);
            } catch (error) {
                console.error("Error fetching pie data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        setLineOptions(getLineOptions(window.innerWidth));
        const handleResize = () => {
            setLineOptions(getLineOptions(window.innerWidth));
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className={styles.container}>
                        <Line
                            style={{ backgroundColor: '#060F11' }}
                            data={lineChartData}
                            options={lineOptions}
                            plugins={[verticalLinePlugin]} // No centerTextPlugin for the line chart
                        />

        </div>
    );
};

export default LineChart;
